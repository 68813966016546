import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'
import { useLocation, useNavigate } from 'react-router-dom'

import { usePatientReceiptByDocumentIdQueryQuery } from '../../generated/hooks'
// import { useIsTabletOrMobile } from '../../hooks/mobile-hooks'
import { getInvoiceId, getMemberId } from '../../utils/invoiceUtils'
import LoadingSpinner from './Spinners/LoadingSpinner'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
}

interface InvoiceDataInterface {
  id: string
  document: string
}

interface InvoiceModalProps {
  isOpen?: boolean
}

const InvoiceModal: React.FC<InvoiceModalProps> = ({ isOpen = false }) => {
  // Setup for app navigation based on user actions.
  const navigate = useNavigate()
  const location = useLocation()
  // const isMobile = useIsTabletOrMobile()
  const isMobile = false

  // Initialization for invoice and modal state.
  let subtitle: HTMLHeadingElement | null
  const [modalIsOpen, setIsOpen] = React.useState(isOpen)
  const [invoice, setInvoice] = useState<InvoiceDataInterface | null>(null)
  const [numPages, setNumPages] = useState(1)

  // Functionality for when the modal is to be opened.
  function openModal() {
    setIsOpen(true)
  }

  // Functionality for after the modal is opened.
  function afterOpenModal() {
    if (subtitle) {
      subtitle.style.color = '#f00'
    }
  }

  // Functionality for when the modal is to be closed.
  function closeModal() {
    setIsOpen(false)
  }

  // Navigates to the payment route.
  function goToPay() {
    closeModal()
    navigate('/payment', { state: location.state })
  }

  // Query for getting the invoice/document data, requires contactId and documentId.
  const { data, loading, error } = usePatientReceiptByDocumentIdQueryQuery({
    variables: {
      contactId: getMemberId() || '',
      documentId: getInvoiceId() || '',
    },
  })

  // Sets the invoice state once we have data. Using useEffect to do it once.
  useEffect(() => {
    if (
      data &&
      data.patientReceiptByDocumentId.__typename === 'PatientReceiptByDocumentIdQueryResult' &&
      data.patientReceiptByDocumentId.document !== null
    ) {
      setInvoice({
        id: getInvoiceId() || '',
        document: data.patientReceiptByDocumentId.document || '',
      })
      setNumPages(1)
    }
  }, [data])

  // Show loading or error message if applicable.
  if (loading) {
    return <LoadingSpinner loading={loading} />
  }
  if (error) {
    const errorMessage = document.getElementById('error-message')
    if (errorMessage) {
      errorMessage.innerHTML = error.message
      errorMessage.classList.remove('hidden')
    }
  }

  // Build the pdf document from the base64 string.
  const pdfDocument = (src: string) => {
    return 'data:application/pdf;base64,' + src
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  // Needed in order for the react-pdf library to work.
  pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString()

  // The actual modal component.
  return (
    <div className="invoice-modal">
      <a onClick={openModal} className="view-invoice">
        View Invoice
      </a>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Invoice Modal"
        ariaHideApp={false}
      >
        {/* <div className="modal" tabIndex={-1}> */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal__header d-flex justify-content-between align-items-center">
              <h2 className="modal__title">View Invoice</h2>
              <button
                className="modal__button btn-close top-right"
                onClick={closeModal}
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            {/* <div className="modal-body">{invoice && <embed src={pdfDocument(invoice?.document)} />}</div> */}
            {invoice && (
              <Document
                className="react-pdf__preview--wrapper"
                onLoadSuccess={onDocumentLoadSuccess}
                file={pdfDocument(invoice?.document)}
              >
                {Array.from(new Array(numPages), (_, index) => (
                  <Page
                    width={isMobile ? undefined : 700}
                    className="react-pdf__preview"
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                ))}
              </Document>
            )}
            <div className="modal-footer">
              {invoice && (
                <>
                  <a
                    href={pdfDocument(invoice?.document)}
                    download="invoice.pdf"
                    rel="noreferrer"
                    className="pdf-download"
                  >
                    Download PDF
                  </a>
                  <button onClick={goToPay} className="btn btn-primary">
                    Pay Now
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default InvoiceModal
